import React, { memo, useState } from "react";
import { Input } from "../../Input/Input";
import { useSelector } from "react-redux";

export default memo(({ value, node, field, onValueChange }) => {
  const selectedRowData = useSelector((state) => state.project.selectedRowData);
  const selectedRow = useSelector((state) => state.project.isAnyRowSelected);
  const manualEditRules = useSelector((state) => state.project.manualEditRules);
  const [data1, setValue] = useState();
  let original = value === null ? "" : value;
  let viewed =
    data1 && manualEditRules.length > 0
      ? data1 === "text"
        ? 0
        : data1.charAt(0) === "0"
        ? data1.substring(1)
        : data1
      : original;

  return (
    <Input
      data-testid="custom-component-emission-table"
      type={"decimal"}
      aria-label="manual edit input"
      value={viewed}
      onChange={(event) => {
        if (event.target.value === "") {
          setValue("text");
        } else {
          setValue(event.target.value);
          onValueChange(
            value,
            field,
            event.target.value,
            selectedRowData?.rowData?.data_type
          );
        }
      }}
      disabled={
        // (!selectedRowData?.isAnyRowSelected &&
        //   selectedRow?.rowChecked !== selectedRow?.rowUnchecked) ||
        // selectedRowData?.rowIndex !== node.rowIndex ||
        value === null || value === ""
      }
    />
  );
});
