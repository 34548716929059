import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table } from "../../Table/Table";
import CustomInputRenderer from "./CustomInputRenderer";
import CustomCheckboxRenderer from "./CustomCheckbox";
import {
  disableEditButton,
  editData,
  emissionsEditButtonTooltip,
  emissionsFilterbuttonTooltip,
  filters,
  tablecolumnFilters,
} from "../../../util/constants";
import { suppressEnter, suppressNavigation } from "../../../util/tabUtil";
import {
  setSelectedRowIndex,
  setSelectedRowData,
  setIsAnyRowSelected,
  setSelected,
  setIsManualEdit,
  setManualEditRules,
  getLockingData,
  resetManualEditRules,
} from "../../../store/slices/projectSlice";
import { Button } from "../../Button/Button";
import { Select } from "../../Select/Select";
import { EditIcon, FilterIcon } from "../../../icons";
import { EmissionFilterModal } from "../EmissionsFilterModal/EmissionFilterModal";
import { Tooltip } from "../../CommonUIComponents/Tooltip/Tooltip";
export function EmissionsTable({ loader }) {
  const dispatch = useDispatch();
  const reduceDefaultViewData = useSelector(
    (state) => state.project.defaultViewData
  );
  const isSimulate = useSelector((state) => state.project.isSimulate);
  const isExtend = useSelector((state) => state.project.isExtend);
  const simulateTableData = useSelector(
    (state) => state.project.simulateTableData
  );

  const [selectedColumnFilter, setselectedColumnFilter] = useState(
    "Forecast start year"
  );
  const [rowData, setRowData] = useState([]);
  const [enableEdit, setEnableEdit] = useState(false);
  const [columnDefs, setColumnDefs] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [enableCheckbox, setenablecheckBox] = useState(true);
  const emissionHeadersFilter = useSelector(
    (state) => state.project.selectedUIHeaderFilters
  );

  const project = useSelector((state) => state.project.project);
  const selectedDataType = useSelector(
    (state) => state.project.selectedDataType
  );
  const user = useSelector((state) => state.user.user);
  const [lockedData, setLock] = useState();
  const [lockedBy, setLockedby] = useState();
  // Refresh table view on click of edit button
  useEffect(() => {
    createTableColumns(reduceDefaultViewData.tableHeaderData, 17);
    setselectedColumnFilter("Forecast start year");
  }, [enableEdit]);

  // Show loader
  useEffect(() => {
    if (loader) {
      setShowLoader(true);
    } else {
      setShowLoader(false);
    }
  }, [loader]);

  // Check Default M2 Data to create Dynamic rows and columns
  useEffect(() => {
    if (reduceDefaultViewData.tableData && !isSimulate) {
      setShowLoader(true);
      let data = reduceDefaultViewData?.tableData.filter(
        (element) => element.data_type === selectedDataType
      );
      setRowData([...data]);
      createTableColumns(
        reduceDefaultViewData.tableHeaderData,
        reduceDefaultViewData.tableHeaderData.length
      );
      // setselectedColumnFilter("Forecast start year");
      setShowLoader(false);
    }
    if (isSimulate) {
      setRowData([...simulateTableData]);
    }
  }, [reduceDefaultViewData, isSimulate, simulateTableData, selectedDataType]);

  // On input value change for Manual edit functionality
  const onValueChange = (original, field, value, data_type) => {
    let x = [];
    if (data_type === "Emission factor") {
      x.push({
        start_year: parseInt(field),
        end_year: parseInt(field),
        abatement_value: parseFloat(value),
      });
    } else {
      x.push({
        start_year: parseInt(field),
        end_year: parseInt(field),
        abatement_value:
          ((parseFloat(value) - parseFloat(original)) / parseFloat(original)) *
          100,
      });
    }
    dispatch(setManualEditRules({ array: x }));
  };

  // render collapsed table column
  const renderCollapsedColumn = (index, data) => {
    let dataArr = [];
    data.map((item, i) => {
      if (i === index) {
        dataArr.push({
          columnGroupShow: "closed",
          field: item.field,
          suppressMovable: true,
        });
        dataArr.push({
          columnGroupShow: "open",
          field: item.field,
          suppressMovable: true,
        });
      } else if (
        i > index &&
        i < (index === 0 || index === 7 ? index + 4 : index + 3)
      )
        dataArr.push({
          columnGroupShow: "open",
          field: item.field,
          suppressMovable: true,
        });
    });
    return dataArr;
  };

  // render collpased column name
  const showCollapsedHeaderName = (index) => {
    let headerName = "";
    switch (index) {
      case 0:
        headerName = "Emission";
        break;
      case 4:
        headerName = "Geographical";
        break;
      case 7:
        headerName = "Organisational";
        break;
      case 11:
        headerName = "Custom";
        break;
      default:
    }
    return headerName;
  };

  // Create Dynamic Columns for Reduce Table
  const createTableColumns = (data, length) => {
    // dispatch(setIsAnyRowSelected({ isAnyRowSelected: false }));
    if (data?.length > 0) {
      let colDefs = [];
      // colDefs.push(
      //   {
      //   width: 50,
      //   headerName: "",
      //   suppressMovable: true,
      //   cellRenderer: CustomCheckboxRenderer,
      //   suppressCellFocus: true,
      //   cellRendererParams: () => {
      //     return {
      //       onValueChange: onSelectionChanged,
      //     };
      //   },
      //   pinned: "left",
      //   sortable: false,
      // });

      data.map((item, i) => {
        if (i < length) {
          // if (enableEdit) {
          if (i > 15) {
            colDefs.push({
              field: item.field,
              minWidth: 200,
              flex: 1,
              headerName: item.headerName,
              headerTooltip: item.headerName,
              suppressMovable: true,
              cellRenderer: CustomInputRenderer,
              suppressCellFocus: true,
              suppressKeyboardEvent: (params) => {
                return suppressEnter(params) || suppressNavigation(params);
              },
              cellRendererParams: () => {
                return {
                  field: item.field,
                  onValueChange: onValueChange,
                };
              },
              resizable: true,
              wrapText: true,
              autoHeight: true,
              wrapHeaderText: true,
              autoHeaderHeight: true,
              sortable: false,
            });
          } else {
            if (i === 0 || i === 4 || i === 7 || i === 11) {
              colDefs.push({
                field: item.field,
                minWidth: 150,
                flex: 1,
                headerName: showCollapsedHeaderName(i),
                children: renderCollapsedColumn(i, data),
                marryChildren: true,
                headerTooltip: showCollapsedHeaderName(i),
                suppressMovable: true,
                pinned: i < 4 ? "left" : "",
                resizable: true,
                wrapText: true,
                autoHeight: true,
                wrapHeaderText: true,
                autoHeaderHeight: true,
                sortable: false,
              });
            } else if (i === 14 || i === 15) {
              colDefs.push({
                field: item.field,
                minWidth: 150,
                flex: 1,
                headerName: item.headerName,
                headerTooltip: item.headerName,
                suppressMovable: true,
                resizable: true,
                wrapText: true,
                autoHeight: true,
                wrapHeaderText: true,
                autoHeaderHeight: true,
                sortable: false,
              });
            }
            // if (i > 15) {
            //   colDefs.push({
            //     field: item.field,
            //     minWidth: 150,
            //     flex: 1,
            //     headerName: item.headerName,
            //     headerTooltip: item.headerName,
            //     suppressMovable: true,
            //     pinned: i < 4 ? "left" : "",
            //     resizable: true,
            //     wrapText: true,
            //     autoHeight: true,
            //     wrapHeaderText: true,
            //     autoHeaderHeight: true,
            //     sortable: false,
            //   });
            // }
          }
          //  }
          // else {
          //   if (i === 0 || i === 4 || i === 7 || i === 11) {
          //     colDefs.push({
          //       field: item.field,
          //       minWidth: 150,
          //       flex: 1,
          //       headerName: showCollapsedHeaderName(i),
          //       children: renderCollapsedColumn(i, data),
          //       marryChildren: true,
          //       headerTooltip: showCollapsedHeaderName(i),
          //       suppressMovable: true,
          //       pinned: i < 4 ? "left" : "",
          //       resizable: true,
          //       wrapText: true,
          //       autoHeight: true,
          //       wrapHeaderText: true,
          //       autoHeaderHeight: true,
          //       sortable: false,
          //     });
          //   }
          //   if (i > 15) {
          //     colDefs.push({
          //       field: item.field,
          //       minWidth: 150,
          //       flex: 1,
          //       headerName: item.headerName,
          //       headerTooltip: item.headerName,
          //       suppressMovable: true,
          //       pinned: i < 4 ? "left" : "",
          //       resizable: true,
          //       wrapText: true,
          //       autoHeight: true,
          //       wrapHeaderText: true,
          //       autoHeaderHeight: true,
          //       sortable: false,
          //     });
          //   }
          // }
        }
      });
      setColumnDefs(colDefs);
    }
  };

  // On Row selection
  // const onSelectionChanged = (checked, node) => {
  //   if (checked) {
  //     dispatch(setIsAnyRowSelected({ isAnyRowSelected: checked }));
  //     dispatch(setSelectedRowIndex({ rowIndex: node.rowIndex }));
  //     dispatch(setSelectedRowData({ rowData: node.data }));
  //   } else {
  //     dispatch(setIsAnyRowSelected({ isAnyRowSelected: checked }));
  //     dispatch(setSelected({ rowIndex: node.rowIndex }));
  //     dispatch(resetManualEditRules({ array: [] }));
  //   }
  // };

  // Changing the tablecolumns on change of filters dropdown change
  // const changeColumnFilters = (event) => {
  //   if (event === "10 years") {
  //     createTableColumns(reduceDefaultViewData.tableHeaderData, 27);
  //     setselectedColumnFilter(event);
  //   }
  //   if (event === "All years") {
  //     createTableColumns(
  //       reduceDefaultViewData.tableHeaderData,
  //       reduceDefaultViewData.tableHeaderData?.length
  //     );
  //     setselectedColumnFilter(event);
  //   }
  //   if (event === "Forecast start year") {
  //     createTableColumns(reduceDefaultViewData.tableHeaderData, 17);
  //     setselectedColumnFilter(event);
  //   }
  //   dispatch(setSelectedRowData({ rowData: {} }));
  // };

  // Enabling/ Disabling Manual edit functionality.
  // const enableEditData = () => {
  //   if (enableEdit) {
  //     setEnableEdit(false);
  //     dispatch(setIsManualEdit({ isManualEdit: false }));
  //   } else {
  //     setEnableEdit(true);
  //     dispatch(setIsManualEdit({ isManualEdit: true }));
  //   }
  // };

  // to get a locked user flags
  useEffect(() => {
    if (project.projectId) {
      const request = {
        LockedBy: user.username,
        ModuleName: "Project",
        EngagementID: project.projectId,
      };

      dispatch(getLockingData(request)).then((action) => {
        if (action) {
          const lockedData = action.payload?.data?.lockStatus;
          const lockedBy = action.payload?.data?.lockedBy;
          setLock(lockedData);
          setLockedby(lockedBy);
        }
      });
    }
  }, [dispatch, project.projectId]);

  return (
    <div className="emissions-table">
      <div className="emissions-table-filter-section">
        {/* <Tooltip
          trigger={
            <Button
              variant="primary-alt"
              onClick={() => {
                setIsFilterOpen(true);
              }}
              disabled={
                emissionHeadersFilter.scope === undefined ||
                (lockedData === 1 &&
                  lockedBy !== user.username &&
                  project.projectId)
              }
            >
              <FilterIcon />
              {filters}
            </Button>
          }
          variant="ghost"
          tooltipText={emissionsFilterbuttonTooltip}
        ></Tooltip> */}
        {/* <div className="emissions-table-filter-section-custom">
          {!isExtend && (
            <Tooltip
              trigger={
                <Button
                  variant="primary-alt"
                  onClick={() => enableEditData()}
                  disabled={
                    lockedData === 1 &&
                    lockedBy !== user.username &&
                    project.projectId
                  }
                >
                  <EditIcon />
                  {enableEdit ? disableEditButton : editData}
                </Button>
              }
              variant="ghost"
              tooltipText={emissionsEditButtonTooltip}
            ></Tooltip>
          )}
          <Select
            options={tablecolumnFilters}
            value={selectedColumnFilter}
            onChange={changeColumnFilters}
          ></Select>
        </div> */}
      </div>
      <div className="emissions-table-table-section">
        <Table
          colDefs={columnDefs}
          rowData={rowData}
          rowSelect={"single"}
          draggable={false}
          loading={showLoader}
        />
      </div>
    </div>
  );
}
