export const ENGAGEMENT_URL = {
  SaveUploadedFileDetail: `SaveUploadedFileDetail`,
  UpdateEngagement: `UpdateEngagement`,
  GetAllUploadedFiles: `GetAllFiles`,
  GetEngagement: `GetEngagementById`,
  GetAllEngagements: `GetAll`,
  CreateEngagement: `CreateEngagement`,
  GetAllProjects: `GetAllProjects`,
  UpdateProject: `UpdateProject`,
  GetAllMasterSector: `GetMasterSector`,
  SaveSector: `SaveSector`,
  GetAllsectorL: `GetAllSector`,
  GetAllEngagementTeam: `GetAllTeam`,
  SaveEngagementTeam: `SaveEngagementTeamMember`,
  GetStepperSteps: `GetMasterSetupStep`,
  GetAllRecordsBySpecification: `GetAllSpecs`,
  GetSaSToken: `UploadFileToBlob`,
  GetUploadedFileProgress: `GetUploadedFileProgress`,
  GetHierarchicalLevelData: `GetHierarchicalLevelData`,
  GetTargetValueByScope: `GetTargetValueByScope`,
  GetEmisionValueByScope: `GetEmisionValueByScope`,
  DeleteEngagementTeamMember: `DeleteEngagementTeamMember`,
  CheckIfEngagementCodeExist: `CheckIfEngagementCodeExist`,
  GetHeadersbyModuleForValidation: `GetHeadersbyModuleForValidation`,
  UpdateForecastEndYear: `UpdateForecastEndYear`,
  UpdateMostRecentYear: `UpdateMostRecentYear`,
  UpdateSkipForecast: `UpdateSkipForecast`,
  CalculateEmissions: `CalculateEmissions`,
  GetDataValidationModule1Data: `GetDataValidationModule1Data`,
  GetDataValidationModule2Data: `GetDataValidationModule2Data`,
  resetM2Data: `DeleteM2Data`,
  GetCopletedCountByEngagement: `GetCopletedCountByEngagement`,
  GetM1FileUploadVersionHistory: `GetM1FileUploadVersionHistoryAsync`,
  GetM2FileUploadVersionHistory: `GetM2FileUploadVersionHistory`,
  GetConsentFlag: `GetPIAConsentFlag`,
  UpdateConsentFlag: `SavePIAConsent`,
  GetGMPDataURL: `GetGMPDetails`,
  GetRegions: `GetRegions`,
  SyncModulebyData: `SyncDataByModule`,
  ReleaseAlllocks: `ReleaseAlllocks`,
  GetAllFlags: `GetAllFlagsValue`,
  UploadExcelFile: `UploadExcelFile`,
  UploadTextFile: `UploadTextFile`,
};
