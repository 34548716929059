import React, { useEffect } from "react";
import { useState } from "react";
import "./ImportExcel.scss";
import Tooltip from "@mui/material/Tooltip";
import { Button } from "../../Button/Button";
import { Stepper } from "../../Stepper/Stepper";
import { useDispatch, useSelector } from "react-redux";
import { DownloadLink } from "../../DownloadLink/DownloadLink";
import template from "../../../assets/templates/EyDecarb_Forecast_Template.xlsx";
import {
  impExcelDesc,
  manageEmissionBodyUploadTitle,
  impExcel,
  manageEmissionBodyTemplateTitle,
  manageEmissionBodyUploadSaveFile,
  foreEndYear,
  foreStartYear,
  DownloadTemplateBtn,
  DownloadErrorReportBtn,
  FileUploadIsInProgress,
  FileVerificationIsInProgress,
  FileUploadIsInSuccess,
  DataVerificationIsInProgress,
  DataVerificationIsInFailed,
  GeneratingErrorReport,
  ExcelDataVerificationFailed,
  DataVerificationIsInSuccess,
  DataLoadIsInProgress,
  DataLoadIsInSuccess,
  CancelBtn,
  ApplyBtn,
  FailedStatus,
  DataLoadHasFailed,
  uploadSuccessMessage1,
  uploadSuccessMessage2,
  uploadSuccessMessage3,
  manageEmissionBodyUploadFileHistory,
  saveButtonTooltip,
} from "../../../util/constants";
import { DownloadIcon, SaveIcon } from "../../../icons";
import { FileUploader } from "../../FileUploader/FileUploader";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../routes/routes.constatns";
import { LoadingIndicator } from "../../LoadingIndicator/LoadingIndicator";
import { formarDateTime } from "../../../util/dateUtil";
import {
  getDataValidationModule2Data,
  getHeadersbyModuleForValidation,
  getUploadedFileProgress,
  getUploadedFiles,
  saveManageEmissions,
  updateSkipForecast,
  uploadExcelFile,
} from "../../../store/services/engagement.service";
import { uploadFileToBlob } from "../../../store/services/azureBlob.service";
import { createExcelFile, validateExcel } from "../../../util/excelUtil";
import {
  setShowTargetFlag,
  setSkipForecast,
} from "../../../store/slices/engagementSlice";
import { setShowTarget } from "../../../store/services/forecast.service";
import { RepeatIcon } from "../../../icons/repeat";
import { FileVersionHistoryModal } from "./FileVersionHistoryModal";
import { getFileUploadHistoryM2 } from "../../../store/slices/forecastSlice";
import { saveAs } from "file-saver";
import { LockerModal } from "../../LockerModal/lockerModal";
import { LoadingIndicatorEmbed } from "../../LoadingIndicator/LoadingIndicatorEmbed";

const DataCategory = "Target Data";
const ModuleName = "Module2";
let refreshIntervalId = undefined;
let refreshIntervalCount = 0;

export function ImportExcel() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const steps = useSelector((state) => state.stepper.steps);
  const engagement = useSelector((state) => state.engagement.engagement);
  const selectedRegion = useSelector(
    (state) => state.engagement.selectedRegion
  );
  const stepIndex = useSelector((state) => state.engagement.stepIndex);
  const currentStep = useSelector((state) => state.engagement.currentStep);
  const user = useSelector((state) => state.user.user);
  const [filesToUpload, setFilesToUpload] = useState([]);
  const [percentUploaded, setPercentUploaded] = useState(0);
  const [uploaded, setUploaded] = useState(false);
  const [showPercentage, setShowPercentage] = useState(false);
  const [startUploadProcess, setStartUploadProcess] = useState(false);
  const [startUploadProcessStatus, setStartUploadProcessStatus] = useState("");
  const [excelHeaders, setExcelHeaders] = useState([]);
  const [excelTemplateError, setExcelTemplateError] = useState("");
  const [excelStatus, setExcelStatus] = useState("Error");
  const [isDataSaveInProgress, setIsDataSaveInProgress] = useState(false);
  const [excelDataVerificationFailed, setExcelDataVerificationFailed] =
    useState("");
  const [excelDataVerificationFailedFile, setExcelDataVerificationFailedFile] =
    useState(undefined);
  const [isDeleteButtonDisabled, setIsDeleteButtonDisabled] = useState(false);
  const [newFile, setnewFile] = useState(false);
  const [isLockerModalOpen, setLockerModalOpen] = useState(false);
  const [fileContent, setFileContent] = useState("");
  const [
    isFileVersionHistoryModalVisible,
    setIsFileVersionHistoryModalVisible,
  ] = useState(false);
  const [open, setTooltipOpen] = useState(false);
  const lockedData = useSelector(
    (state) => state.project?.lockingData?.data?.lockStatus
  );
  const lockedBy = useSelector(
    (state) => state.project?.lockingData?.data?.lockedBy
  );

  async function onDrop(files) {
    setPercentUploaded(0);
    setUploaded(false);
    setStartUploadProcess(true);
    setStartUploadProcessStatus(FileVerificationIsInProgress);
    setExcelTemplateError("");
    setExcelDataVerificationFailed("");
    setExcelStatus("Error");
    setIsDataSaveInProgress(false);
    setExcelDataVerificationFailedFile(undefined);
    setIsDeleteButtonDisabled(false);
    setShowPercentage(true);

    const data =
      excelHeaders.length === 0
        ? await getHeadersbyModuleForValidation({
            request: { moduleName: ModuleName },
          })
        : excelHeaders;
    setExcelHeaders(data);
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = btoa(reader.result);
      setFileContent(base64String);
    };
    reader.readAsDataURL(files[0]);

    validateExcel(files[0], data, (result) => {
      if (result.status === "Error") {
        setExcelTemplateError(result.message);
        setExcelStatus(result.status);
        setStartUploadProcess(false);
        setStartUploadProcessStatus("");
      } else {
        setExcelTemplateError("");
        setFilesToUpload(files);
        setTooltipOpen(true);
        setExcelStatus(result.status);
        setStartUploadProcess(false);
        setStartUploadProcessStatus("");
      }
    });
  }

  async function onSaveFileClickHandler() {
    setTooltipOpen(false);
    setnewFile(true);
    setShowPercentage(false);

    const file = filesToUpload[0];
    const fileName = `EYDecarb_${
      engagement.engagementCode
    }_${ModuleName}_${formarDateTime(new Date(), "ddMMyyyy HH:mm:ss")}.xlsx`;

    const request = {
      engagementId: engagement.id,
      clientName: engagement.clientName,
      sectorId: engagement.sectorId,
      dataCategory: DataCategory,
      fileName: fileName,
      uploadedBy: user.username,
      foreCastEndYear: parseFloat(engagement.foreCastEndYear),
      mostRecentYear: parseFloat(engagement.mostRecentYear),
    };

    setStartUploadProcess(true);
    setStartUploadProcessStatus(FileUploadIsInProgress);
    setIsDeleteButtonDisabled(true);

    try {
      await saveManageEmissions({ request });
      // await uploadFileToBlob({
      //   file: file,
      //   moduleName: `DecarbActivityData/${ModuleName}`,
      //   fileName: fileName,
      // });
      // const formData = new FormData();
      // formData.append("file", file);
      // formData.append("filename", fileName);
      // request = formData;
      request = {
        FileName: fileName,
        ContentType:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        FileContent: fileContent,
      };
      await uploadExcelFile({ request });

      refreshIntervalId = window.setInterval(checkUploadStatus, 10000);
    } catch (error) {
      setStartUploadProcess(false);
    }
  }

  async function checkUploadStatus() {
    refreshIntervalCount++;
    if (refreshIntervalCount > 100) {
      window.clearInterval(refreshIntervalId);
      refreshIntervalId = undefined;
      setIsDeleteButtonDisabled(false);
      setStartUploadProcessStatus(
        "Data load has failed. This may have occurred due to some technical error. Please try again and contact support team if issue persists."
      );

      return;
    }

    const request = { engagementId: engagement.id };
    const response = await getUploadedFileProgress({ request });
    setStartUploadProcessStatus(FileUploadIsInProgress);

    if (response.data.length > 0) {
      const filteredData = response.data.filter(
        (x) => x.dataCategory === DataCategory
      );

      const data = filteredData.sort((a, b) =>
        a.fileName.localeCompare(b.fileName)
      )[filteredData.length - 1];

      if (data && data.dataCategory === DataCategory) {
        if (data.loadedToBLob === 1) {
          let uploadPercent = 25;
          let uploadPercentStatus = FileUploadIsInSuccess;
          let finalSuccesMessage = "";

          if (
            data.dataValidation_Status?.toLowerCase() ===
            "In Progress".toLowerCase()
          ) {
            uploadPercent = 50;
            uploadPercentStatus = DataVerificationIsInProgress;
          }
          if (
            data.dataValidation_Status?.toLowerCase() === "Failed".toLowerCase()
          ) {
            uploadPercent = 50;
            uploadPercentStatus = DataVerificationIsInFailed;
            setPercentUploaded(uploadPercent);
            setStartUploadProcessStatus(uploadPercentStatus);

            if (refreshIntervalId) {
              window.clearInterval(refreshIntervalId);
              refreshIntervalId = undefined;
            }
            setIsDeleteButtonDisabled(false);

            const errorData = await getDataValidationModule2Data({
              request: { engagementCode: engagement.engagementCode },
            });

            if (errorData && errorData.length > 0) {
              setExcelDataVerificationFailed(GeneratingErrorReport);

              const generatedExcel = await createExcelFile([
                {
                  data: errorData,
                  name: "Errors",
                },
              ]);
              const url = window.URL.createObjectURL(generatedExcel);
              setExcelDataVerificationFailedFile(url);
              setExcelDataVerificationFailed(
                ExcelDataVerificationFailed.replace("$1", errorData.length)
              );
            }
          }
          if (
            data.dataValidation_Status?.toLowerCase() ===
            "Success".toLowerCase()
          ) {
            uploadPercent = 50;
            uploadPercentStatus = "Getting ready for data load";

            if (
              data.stagingUpload.toLowerCase() === "In Progress".toLowerCase()
            ) {
              uploadPercent = 75;
              uploadPercentStatus = DataVerificationIsInProgress;
            }
            if (data.stagingUpload.toLowerCase() === "Failed".toLowerCase()) {
              uploadPercent = 75;
              uploadPercentStatus = DataLoadHasFailed;

              setPercentUploaded(uploadPercent);
              setStartUploadProcessStatus(uploadPercentStatus);

              if (refreshIntervalId) {
                window.clearInterval(refreshIntervalId);
                refreshIntervalId = undefined;
              }
              setIsDeleteButtonDisabled(false);
            }
            if (data.stagingUpload.toLowerCase() === "Success".toLowerCase()) {
              uploadPercent = 75;
              uploadPercentStatus = DataVerificationIsInSuccess;

              if (
                data.module1Refresh.toLowerCase() ===
                "In Progress".toLowerCase()
              ) {
                uploadPercent = 90;
                uploadPercentStatus = DataLoadIsInProgress;
              }
              if (
                data.module1Refresh.toLowerCase() === "Failed".toLowerCase()
              ) {
                uploadPercent = 90;
                uploadPercentStatus = DataLoadHasFailed;

                setPercentUploaded(uploadPercent);
                setStartUploadProcessStatus(uploadPercentStatus);

                if (refreshIntervalId) {
                  window.clearInterval(refreshIntervalId);
                  refreshIntervalId = undefined;
                }
                setIsDeleteButtonDisabled(false);
              }
              if (
                data.module1Refresh.toLowerCase() === "Success".toLowerCase()
              ) {
                uploadPercentStatus = DataLoadIsInSuccess;
                uploadPercent = 100;
                if (data.final_record_count !== 0) {
                  finalSuccesMessage =
                    uploadSuccessMessage1 +
                    data.staging_record_count +
                    uploadSuccessMessage2;
                }
                if (data.final_record_count === 0) {
                  finalSuccesMessage = uploadSuccessMessage3;
                }

                setIsDeleteButtonDisabled(false);
              }
            }
          }

          setPercentUploaded(uploadPercent);
          setStartUploadProcessStatus(uploadPercentStatus);

          if (uploadPercent !== 100) {
            setStartUploadProcess(true);
            setExcelTemplateError("");
          } else {
            setUploaded(true);
            setShowPercentage(false);
            setTooltipOpen(false);
            setExcelTemplateError(finalSuccesMessage);
            setStartUploadProcess(false);
            if (newFile) {
              dispatch(setShowTargetFlag({ show: 0 }));
              await setShowTarget({
                request: { engagementId: engagement.id, status: 0 },
              });
            }

            if (refreshIntervalId) {
              window.clearInterval(refreshIntervalId);
              refreshIntervalId = undefined;
            }
          }
        } else {
          let uploadPercent = 0;
          let uploadPercentStatus = FileUploadIsInProgress;
          setPercentUploaded(uploadPercent);
          setStartUploadProcessStatus(uploadPercentStatus);
          setStartUploadProcess(true);
        }
      }
    }
  }

  function handleCancelClick() {
    navigate(`/${routes.createNewEngagement}`, {
      state: {
        engagementId: engagement.id,
        regionId: selectedRegion.id,
      },
      gestureEnabled: false,
    });
  }

  async function handleApplyClick() {
    setIsDataSaveInProgress(true);
    try {
      await updateSkipForecast({
        request: {
          engagementId: engagement.id,
          skipvalue: 2,
        },
      });
      dispatch(setSkipForecast({ skipForecast: 2 }));
    } catch (e) {
      console.error(e);
    }

    setIsDataSaveInProgress(false);

    navigate(`/${routes.createNewEngagement}`, {
      state: {
        engagementId: engagement.id,
        regionId: selectedRegion.id,
      },
      gestureEnabled: false,
    });
  }

  useEffect(() => {
    async function fetchData() {
      const request = { id: engagement.id };
      const data = await getUploadedFiles({ request });
      if (data || data.data.length > 0) {
        const fl = data.data.find((x) => x.dataCategory === DataCategory);

        if (fl) {
          setFilesToUpload([{ name: fl.fileName }]);
          checkUploadStatus();
          refreshIntervalId = window.setInterval(checkUploadStatus, 10000);
        }
      }
    }

    if (
      engagement.id &&
      engagement.m2_validated &&
      engagement.m2_validated.toUpperCase() !== "N"
    ) {
      fetchData();
    }
  }, [engagement?.id, engagement.m2_validated, dispatch]);

  function onDelete() {
    setFilesToUpload([]);
    setPercentUploaded(0);
    setUploaded(false);
    setTooltipOpen(false);
    setStartUploadProcess(false);
    setStartUploadProcessStatus("");
    setExcelTemplateError("");
    setExcelDataVerificationFailed("");
    setExcelStatus("Error");
    setIsDataSaveInProgress(false);
    setExcelDataVerificationFailedFile(undefined);

    if (refreshIntervalId) {
      window.clearInterval(refreshIntervalId);
      refreshIntervalId = undefined;
    }
  }

  useEffect(
    () => () => {
      window.clearInterval(refreshIntervalId);
      refreshIntervalId = undefined;
      setFilesToUpload([]);
      setPercentUploaded(0);
      setUploaded(false);
      setShowPercentage(false);
      setStartUploadProcess(false);
      setStartUploadProcessStatus("");
      setExcelTemplateError("");
      setExcelDataVerificationFailed("");
      setExcelStatus("Error");
      setIsDataSaveInProgress(false);
      setExcelDataVerificationFailedFile(undefined);
    },
    []
  );

  function onFileVersionClickHandler() {
    setIsFileVersionHistoryModalVisible(true);

    const request = {
      engagementId: engagement.id,
    };
    dispatch(getFileUploadHistoryM2(request));
  }

  return (
    <div className="forecast-import-excel">
      <div className="forecast-import-excel-eng-header">
        <div className="forecast-import-excel-eng-header-title">
          {steps[currentStep]?.stepHeading}
        </div>
        <div className="forecast-import-excel-eng-header-stepper">
          <Stepper
            steps={steps}
            currentStep={currentStep}
            stepIndex={stepIndex}
          />
        </div>
      </div>
      <div className="forecast-import-excel-content">
        <div className="forecast-import-excel-header">
          <span className="forecast-import-excel-header-main">{impExcel}</span>
          <span className="forecast-import-excel-header-desc">
            {impExcelDesc}
          </span>
        </div>
        <div className="forecast-import-excel-body">
          <div className="forecast-import-excel-body-template">
            <span className="forecast-import-excel-body-template-title">
              {manageEmissionBodyTemplateTitle}
            </span>
            <DownloadLink template={template} text={DownloadTemplateBtn} />
          </div>
          <div className="forecast-import-excel-body-upload">
            <div className="forecast-import-excel-body-upload-year">
              <span className="forecast-import-excel-body-upload-year-label">
                {foreStartYear}
              </span>
              <span className="forecast-import-excel-body-upload-year-label-l">
                {engagement.mostRecentYear}
              </span>
              <span className="forecast-import-excel-body-upload-year-label">
                {foreEndYear}
              </span>
              <span className="forecast-import-excel-body-upload-year-label-l">
                {engagement.foreCastEndYear}
              </span>
              <Button
                onClick={onFileVersionClickHandler}
                variant="text"
                disabled={filesToUpload.length === 0 || startUploadProcess}
                className={"manage-emissions-body-upload-file-history-button"}
              >
                <RepeatIcon />
                {manageEmissionBodyUploadFileHistory}
              </Button>
            </div>
            <div className="forecast-import-excel-body-upload-section">
              <span className="forecast-import-excel-body-upload-title">
                {manageEmissionBodyUploadTitle}
              </span>
              <div className="forecast-import-excel-body-upload-file">
                <div className="forecast-import-excel-body-upload-file-uploader">
                  <FileUploader
                    filesToUpload={filesToUpload}
                    onDrop={onDrop}
                    accept={{
                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                        [".xlsx"],
                    }}
                    multiple={false}
                    percentUploaded={percentUploaded}
                    onDelete={onDelete}
                    uploaded={uploaded || showPercentage}
                    disabled={
                      uploaded ||
                      startUploadProcess ||
                      (lockedData === 1 && lockedBy !== user.username)
                    }
                    isDeleteButtonDisabled={
                      isDeleteButtonDisabled ||
                      (lockedData === 1 && lockedBy !== user.username)
                    }
                  />
                </div>
                <div
                  className={
                    startUploadProcess
                      ? "show-file-upload-status"
                      : "hide-file-upload-status"
                  }
                >
                  <span>{startUploadProcessStatus}</span>
                  <LoadingIndicator
                    show={
                      !startUploadProcessStatus
                        .toLowerCase()
                        .includes(FailedStatus.toLowerCase())
                    }
                    fullscreen={false}
                  />
                </div>
                <div className="forecast-import-excel-body-upload-uploader-manage-file-buttons">
                  <Tooltip
                    open={open}
                    placement="top"
                    arrow
                    title={saveButtonTooltip}
                  >
                    <div>
                      <Button
                        onClick={onSaveFileClickHandler}
                        variant="primary-alt"
                        disabled={
                          filesToUpload.length === 0 ||
                          uploaded ||
                          startUploadProcess
                        }
                      >
                        <SaveIcon />
                        {manageEmissionBodyUploadSaveFile}
                      </Button>
                    </div>
                  </Tooltip>
                </div>

                <div
                  className={`${
                    excelStatus === "Success"
                      ? "template-upload-success"
                      : "template-upload-error"
                  } ${
                    excelTemplateError
                      ? "show-file-upload-status"
                      : "hide-file-upload-status"
                  }`}
                >
                  {excelTemplateError}
                </div>
                <div
                  className={`excel-upload-data-verification-error ${
                    excelDataVerificationFailed
                      ? "show-file-upload-status"
                      : "hide-file-upload-status"
                  }`}
                >
                  <span>{excelDataVerificationFailed}</span>

                  <LoadingIndicatorEmbed
                    show={
                      excelDataVerificationFailed &&
                      !excelDataVerificationFailedFile
                    }
                    fullscreen={false}
                  >
                    <Button
                      onClick={() => {
                        const fileName = `EYDecarb_${
                          engagement.engagementCode
                        }_${ModuleName}_DataLoadErrors_${formarDateTime(
                          new Date(),
                          "ddMMyyyy HH:mm:ss"
                        )}.xlsx`;
                        saveAs(excelDataVerificationFailedFile, fileName);
                      }}
                      variant="primary-alt"
                      className={"forecast-download-error-report-button"}
                    >
                      <DownloadIcon />
                      {DownloadErrorReportBtn}
                    </Button>
                  </LoadingIndicatorEmbed>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="forecast-import-excel-footer">
        <Button
          variant="secondary"
          onClick={handleCancelClick}
          disabled={isDataSaveInProgress}
        >
          {CancelBtn}
        </Button>
        <LoadingIndicatorEmbed show={isDataSaveInProgress} fullscreen={false}>
          <Button
            variant="primary"
            onClick={handleApplyClick}
            disabled={!uploaded || isDataSaveInProgress}
          >
            {ApplyBtn}
          </Button>
        </LoadingIndicatorEmbed>
      </div>
      <FileVersionHistoryModal
        isModalVisible={isFileVersionHistoryModalVisible}
        onClose={() => {
          setIsFileVersionHistoryModalVisible(false);
        }}
      />
      {lockedData === 2 && lockedBy === user.username && (
        <LockerModal
          isOpen={isLockerModalOpen}
          onClose={() => setLockerModalOpen(false)}
        />
      )}
    </div>
  );
}
