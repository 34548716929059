import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getM3GraphData,
  getUserLockByModule,
  getUserModuleLock,
} from "../services/project.service";
// import { saveEmissionFiltersReduce } from "../services/project.service";

const initialState = {
  project: {},
  tabIndex: 0,
  currentTab: 0,
  isNextTabButtonDisabled: true,
  isSelectAll: true,
  loading: false,
  error: null,
  hierarchialData: [],
  selectedRowData: {},
  defaultViewData: {},
  simulateTableData: [],
  isSimulate: false,
  isGraphSimulate: false,
  simulateGraphData: false,
  isEditProject: false,
  overallFilters: {},
  emissionfiltersSaved: [],
  selectedUIHeaderFilters: {},
  selectedUIAllFilters: {},
  changeLogPayloadData: [],
  appliedFilterPayload: {},
  resultsEmissionAbatementChartData: [],
  resultsEmissionAbatementChartDataLoading: false,
  selectedRules: [],
  displayData: [],
  isAnyRowSelected: {},
  editButton: false,
  isManualEdit: false,
  manualEditRules: [],
  isResetApplied: false,
  lockingData: {},
  lockingModuleData: {},
  projectAbatementData: [],
  projectCashFlowData: {},
  isSimulateorSaveApplied: false,
  isExtend: false,
  isTableFiltersApplied: false,
  isProjectValidated: true,
  lastAppliedFilters: {},
  graphTableLoader: true,
  emissionFiltersApplied: false,
  selectedDataType: "Activity data",
  dropdownData: {},
  unitValidationFlag: {},
};

export const getEmissionAbatementData = createAsyncThunk(
  "Project/GetM3GraphData",
  async (request) => {
    const apiResponse = await getM3GraphData({ request });

    return apiResponse;
  }
);

export const getLockingData = createAsyncThunk(
  "Project/getUserModuleLock",
  async (request) => {
    const apiResponse = await getUserModuleLock({ request });

    return apiResponse;
  }
);

export const getUserLockByModuleData = createAsyncThunk(
  "Project/getUserLockByModule",
  async (request) => {
    const apiResponse = await getUserLockByModule({ request });

    return apiResponse;
  }
);

const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    previousTab(state) {
      state.currentTab = state.currentTab - 1;
    },
    nextTab(state) {
      state.currentTab = state.currentTab + 1;
    },
    setCurrentTab(state, action) {
      const { currentTab } = action.payload;

      state.currentTab = currentTab;
    },
    setTabIndex(state, action) {
      const { index } = action.payload;

      state.tabIndex = index;
    },
    setIsNextTabButtonDisabled(state, action) {
      const { isDisabled } = action.payload;

      state.isNextTabButtonDisabled = isDisabled;
    },
    setProjectName(state, action) {
      const { name } = action.payload;
      state.project = { ...state.project, projectName: name };
    },
    setProjectType(state, action) {
      const { type } = action.payload;
      state.project = { ...state.project, projectType: type };
    },
    setProjectDescrption(state, action) {
      const { descrp } = action.payload;
      state.project = { ...state.project, projectDescrption: descrp };
    },
    setProjectTags(state, action) {
      const { tags } = action.payload;
      state.project = { ...state.project, projectTags: tags };
    },
    setProjectId(state, action) {
      const { id } = action.payload;
      state.project = { ...state.project, projectId: id };
    },
    setHierarchialData(state, action) {
      const { hierarchialData } = action.payload;

      state.hierarchialData = hierarchialData;
    },
    setReduceDefautViewData(state, action) {
      const { defaultViewData } = action.payload;
      state.defaultViewData = defaultViewData;
    },
    setSimulateDataOnTable(state, action) {
      const { simulateTableData } = action.payload;
      state.simulateTableData = simulateTableData;
    },
    setSimulateFlag(state, action) {
      const { isSimulate } = action.payload;
      state.isSimulate = isSimulate;
    },
    setGraphSimulateFlag(state, action) {
      const { isGraphSimulate } = action.payload;
      state.isGraphSimulate = isGraphSimulate;
    },
    setSimulateDataOnGraph(state, action) {
      const { simulateGraphData } = action.payload;
      state.simulateGraphData = simulateGraphData;
    },
    setSelectedRowIndex(state, action) {
      const { rowIndex } = action.payload;
      state.selectedRowData = { ...state.selectedRowData, rowIndex: rowIndex };
      state.isAnyRowSelected = {
        ...state.isAnyRowSelected,
        rowChecked: rowIndex,
      };
    },
    setSelectedRowData(state, action) {
      const { rowData } = action.payload;
      state.selectedRowData = { ...state.selectedRowData, rowData: rowData };
    },
    setIsAnyRowSelected(state, action) {
      const { isAnyRowSelected } = action.payload;
      state.selectedRowData = {
        ...state.selectedRowData,
        isAnyRowSelected: isAnyRowSelected,
      };
    },
    setSelected(state, action) {
      const { rowIndex } = action.payload;
      state.isAnyRowSelected = {
        ...state.isAnyRowSelected,
        rowUnchecked: rowIndex,
      };
    },
    setFilters(state, action) {
      const { overallFilters } = action.payload;
      state.overallFilters = overallFilters;
    },
    setUISelectedHeaderFilters(state, action) {
      const { selectedUIHeaderFilters } = action.payload;
      state.selectedUIHeaderFilters = selectedUIHeaderFilters;
    },
    setselectedUIAllFilters(state, action) {
      const { selectedUIAllFilters } = action.payload;
      state.selectedUIAllFilters = selectedUIAllFilters;
    },
    setEmissionFiltersSaved(state, action) {
      const { selectedEmissionFilters } = action.payload;
      state.emissionfiltersSaved = selectedEmissionFilters;
    },
    setEmissionChangeLogTableData(state, action) {
      const { changeLogTableData } = action.payload;
      state.changeLogPayloadData = changeLogTableData;
    },
    setAppliedFiltersPayload(state, action) {
      const { filtersPayload } = action.payload;
      state.appliedFilterPayload = filtersPayload;
    },
    setIsEditProject(state, action) {
      const { isEdit } = action.payload;
      state.isEditProject = isEdit;
    },
    setSelectedRules(state, action) {
      const { selectedRules } = action.payload;
      state.selectedRules = selectedRules;
    },
    setDisplayDataChangelog(state, action) {
      const { displayData } = action.payload;
      state.displayData = displayData;
    },
    setEditDataButton(state, action) {
      const { data } = action.payload;
      state.editButton = data;
    },
    setIsManualEdit(state, action) {
      const { isManualEdit } = action.payload;
      state.isManualEdit = isManualEdit;
    },
    setManualEditRules(state, action) {
      const { array } = action.payload;
      state.manualEditRules = [...state.manualEditRules, ...array];
    },
    resetManualEditRules(state, action) {
      const { array } = action.payload;
      state.manualEditRules = [...array];
    },
    setIsReset(state, action) {
      const { reset } = action.payload;
      state.isResetApplied = reset;
    },
    setProjectAbatementData(state, action) {
      const { abatementData } = action.payload;
      state.projectAbatementData = abatementData;
    },
    setProjectCashFlowData(state, action) {
      const { cashFlowData } = action.payload;
      state.projectCashFlowData = cashFlowData;
    },
    setTogglechecked(state, action) {
      const { isExtend } = action.payload;
      state.isExtend = isExtend;
    },
    setTableFiltersApplied(state, action) {
      const { isApplied } = action.payload;
      state.isTableFiltersApplied = isApplied;
    },
    setIsValidated(state, action) {
      const { isValidated } = action.payload;
      state.isProjectValidated = isValidated;
    },
    setLastAppliedFilters(state, action) {
      const { lastAppliedFilters } = action.payload;
      state.lastAppliedFilters = lastAppliedFilters;
    },
    setM3Step2Loader(state, action) {
      const { step2GraphTableLoader } = action.payload;
      state.graphTableLoader = step2GraphTableLoader;
    },
    setM3EmissionFilters(state, action) {
      const { emissionFiltersApplied } = action.payload;
      state.emissionFiltersApplied = emissionFiltersApplied;
    },
    setSelectedDataType(state, action) {
      const { dataType } = action.payload;
      state.selectedDataType = dataType;
    },
    setDropdownMetadata(state, action) {
      const { data } = action.payload;
      state.dropdownData = data;
    },
    setUnitValidationFlag(state, action) {
      const { flag } = action.payload;
      state.unitValidationFlag = flag;
    },
    dropProjectState(state) {
      state.project = initialState.project;
      state.currentTab = initialState.currentTab;
      state.tabIndex = initialState.tabIndex;
      state.isNextTabButtonDisabled = initialState.isNextTabButtonDisabled;
      state.isSelectAll = initialState.isSelectAll;
      state.loading = initialState.loading;
      state.error = initialState.error;
      state.hierarchialData = initialState.hierarchialData;
      state.selectedRowData = initialState.selectedRowData;
      state.defaultViewData = initialState.defaultViewData;
      state.simulateTableData = initialState.simulateTableData;
      state.isSimulate = initialState.isSimulate;
      state.isGraphSimulate = initialState.isGraphSimulate;
      state.simulateGraphData = initialState.simulateGraphData;
      state.isEditProject = initialState.isEditProject;
      state.overallFilters = initialState.overallFilters;
      state.emissionfiltersSaved = initialState.emissionfiltersSaved;
      state.selectedUIHeaderFilters = initialState.selectedUIHeaderFilters;
      state.selectedUIAllFilters = initialState.selectedUIAllFilters;
      state.changeLogPayloadData = initialState.changeLogPayloadData;
      state.appliedFilterPayload = initialState.appliedFilterPayload;
      state.resultsEmissionAbatementChartData =
        initialState.resultsEmissionAbatementChartData;
      state.resultsEmissionAbatementChartDataLoading =
        initialState.resultsEmissionAbatementChartDataLoading;
      state.projectCashFlowData = initialState.projectCashFlowData;
      state.selectedRules = initialState.selectedRules;
      state.displayData = initialState.displayData;
      state.isAnyRowSelected = initialState.isAnyRowSelected;
      state.selectedRowData = initialState.selectedRowData;
      state.editButton = initialState.editButton;
      state.isManualEdit = initialState.isManualEdit;
      state.manualEditRules = initialState.manualEditRules;
      state.isResetApplied = initialState.isResetApplied;
      state.isExtend = initialState.isExtend;
      state.isTableFiltersApplied = initialState.isTableFiltersApplied;
      state.isProjectValidated = initialState.isProjectValidated;
      state.lastAppliedFilters = initialState.lastAppliedFilters;
      state.graphTableLoader = initialState.graphTableLoader;
      state.emissionFiltersApplied = initialState.emissionFiltersApplied;
      state.selectedDataType = initialState.selectedDataType;
      state.dropdownData = initialState.dropdownData;
      state.unitValidationFlag = initialState.unitValidationFlag;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getEmissionAbatementData.pending, (state) => {
        state.resultsEmissionAbatementChartDataLoading = true;
      })
      .addCase(getEmissionAbatementData.fulfilled, (state, action) => {
        state.resultsEmissionAbatementChartDataLoading = false;
        state.resultsEmissionAbatementChartData = action.payload;
      })
      .addCase(getEmissionAbatementData.rejected, (state, action) => {
        state.resultsEmissionAbatementChartDataLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(getLockingData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLockingData.fulfilled, (state, action) => {
        state.loading = false;
        state.lockingData = action.payload;
      })
      .addCase(getLockingData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(getUserLockByModuleData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserLockByModuleData.fulfilled, (state, action) => {
        state.loading = false;
        state.lockingModuleData = action.payload;
      })
      .addCase(getUserLockByModuleData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  previousTab,
  nextTab,
  setIsNextTabButtonDisabled,
  dropProjectState,
  setCurrentTab,
  setTabIndex,
  setProjectName,
  setProjectType,
  setProjectDescrption,
  setProjectTags,
  setProjectId,
  setHierarchialData,
  setReduceDefautViewData,
  setSimulateDataOnTable,
  setSimulateFlag,
  setSimulateDataOnGraph,
  setGraphSimulateFlag,
  setSelectedRowIndex,
  setSelectedRowData,
  setIsAnyRowSelected,
  setFilters,
  setUISelectedHeaderFilters,
  setselectedUIAllFilters,
  setEmissionFiltersSaved,
  setEmissionChangeLogTableData,
  setAppliedFiltersPayload,
  setIsEditProject,
  setSelectedRules,
  setDisplayDataChangelog,
  setSelected,
  setEditDataButton,
  setIsManualEdit,
  setManualEditRules,
  resetManualEditRules,
  setIsReset,
  setProjectAbatementData,
  setProjectCashFlowData,
  setTogglechecked,
  setTableFiltersApplied,
  setIsValidated,
  setLastAppliedFilters,
  setM3Step2Loader,
  setM3EmissionFilters,
  setSelectedDataType,
  setDropdownMetadata,
  setUnitValidationFlag,
} = projectSlice.actions;

export default projectSlice.reducer;
